/* 4. Logos Page ================================= */
.mainContainer .logosWrap {
	position: relative;
	margin: 20px auto 0px;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
}

@media (min-width: 768px) {
	.mainContainer .logosWrap {
		width: 98.75%;
	}
}

@media (min-width: 1440px) {
	.mainContainer .logosWrap {
		width: 99.25%;
	}
}

.mainContainer .logosWrap .logoBox {
	background: url(../assets/logos/sunport.gif) no-repeat;
	background-size: cover;
	background-position: center;
	position: relative;
	margin: 150px auto 7px;
	width: 96%;
	height: 350px;
	transition: .3s;
	cursor: pointer;
}

@media (min-width: 768px) {
	.mainContainer .logosWrap .logoBox {
		width: 49%;
		height: 350px;
	}
}

@media (min-width: 1440px) {
	.mainContainer .logosWrap .logoBox {
		width: 32.5%;
		margin: 150px auto 7px;
		height: 510px;
	}
	.mainContainer .logosWrap .logoBox:hover .logoOverlay {
		opacity: 1;
		transition: .3s;
	}
}

.mainContainer .logosWrap .logoBox .logoOverlay {
	position: absolute;
	top: 350px;
	left: 0;
	padding: 27px 20px;
	width: 100%;
	height: 150px;
	font-family: niveau-grotesk, sans-serif;
	font-size: 18px;
	font-weight: 300;
	line-height: 24px;
	text-align: center;
	background: rgba(255, 231, 55, 0.95);
	color: white;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-end;
	transition: .3s;
}

@media (min-width: 1440px) {
	.mainContainer .logosWrap .logoBox .logoOverlay {
		align-items: center;
		opacity: 0;
		top: 0;
		height: 100%;
		width: 100%;
		padding: 0px 50px;
		font-size: 30px;
		line-height: 43px;
	}
}

.mainContainer .logosWrap .logoBox .overlayStatic {
	top: 280px;
}

@media (min-width: 1440px) {
	.mainContainer .logosWrap .logoBox .overlayStatic {
		top: 0px;
	}
}

.mainContainer .logosWrap .logoBox .logoHeader {
	position: relative;
	margin: 350px auto 0px;
	width: 100%;
	height: auto;
	font-family: niveau-grotesk, sans-serif;
	font-size: 36px;
	line-height: 52px;
	padding: 16px 0px;
	font-weight: 400;
	text-align: center;
	background: none;
	text-decoration: none;
	color: white;
	z-index: 10;
}

@media (min-width: 1440px) {
	.mainContainer .logosWrap .logoBox .logoHeader {
		font-size: 42px;
		margin: 510px auto 0px;
		color: black;
	}
}

.mainContainer .logosWrap .logoBox .headerStatic {
	margin: 280px auto 0px;
}

@media (min-width: 1440px) {
	.mainContainer .logosWrap .logoBox .headerStatic {
		top: 230px;
	}
}

.mainContainer .logosWrap .logoBoxZero_1 {
	background: url(../assets/logos/touchsource.gif) no-repeat;
	background-size: cover;
	background-position: center;
	margin: 0 auto 7px;
}

.mainContainer .logosWrap .logoBoxZero_1 .logoOverlay {
	color: white;
	background: rgba(7, 159, 196, 0.97);
}

.mainContainer .logosWrap .logoBoxZero_1 .logoHeader {
	color: white;
}

@media (min-width: 1440px) {
	.mainContainer .logosWrap .logoBoxZero_1 .logoHeader {
		color: black;
	}
}

@media (min-width: 1440px) {
	.mainContainer .logosWrap .logoBoxZero_1 {
		margin: 0 auto 7px;
	}
}

.mainContainer .logosWrap .logoBoxZero_2 {
	background: url(../assets/logos/fishLoop_Square.gif) no-repeat;
	background-size: cover;
	background-position: center;
	margin: 150px auto 7px;
}

.mainContainer .logosWrap .logoBoxZero_2 .logoOverlay {
	color: white;
	background: rgba(21, 1, 52, 0.95);
}

.mainContainer .logosWrap .logoBoxZero_2 .logoHeader {
	color: white;
}

@media (min-width: 1440px) {
	.mainContainer .logosWrap .logoBoxZero_2 .logoHeader {
		color: black;
	}
}

@media (min-width: 768px) {
	.mainContainer .logosWrap .logoBoxZero_2 {
		margin: 0px auto 7px;
	}
}

.mainContainer .logosWrap .logoBoxOne .logoOverlay {
	color: black;
}

.mainContainer .logosWrap .logoBoxOne .logoHeader {
	color: black;
}

@media (min-width: 1440px) {
	.mainContainer .logosWrap .logoBoxOne {
		margin: 0 auto 7px;
	}
}

.mainContainer .logosWrap .logoBoxTwo {
	background: url(../assets/logos/moh.gif) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .logosWrap .logoBoxTwo .logoOverlay {
	background-color: rgba(21, 128, 132, 0.95);
}

.mainContainer .logosWrap .logoBoxThree {
	background: url(../assets/logos/urban.gif) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .logosWrap .logoBoxThree .logoOverlay {
	background-color: rgba(64, 11, 117, 0.91);
}

.mainContainer .logosWrap .logoBoxFour {
	background: url(../assets/logos/jds.gif) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .logosWrap .logoBoxFour .logoOverlay {
	background-color: rgba(255, 255, 255, 0.95);
	color: black;
}

.mainContainer .logosWrap .logoBoxFour .logoHeader {
	color: black;
}

.mainContainer .logosWrap .logoBoxFive {
	background: url(../assets/logos/asianGames.gif) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .logosWrap .logoBoxFive .logoOverlay {
	background-color: rgba(117, 19, 50, 0.95);
}

.mainContainer .logosWrap .logoBoxSix {
	background: white;
	background-size: cover;
	background-position: center;
}

.mainContainer .logosWrap .logoBoxSix iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.mainContainer .logosWrap .logoBoxSix .logoOverlay {
	background-color: rgba(232, 61, 85, 0.97);
}

@media (min-width: 1440px) {
	.mainContainer .logosWrap .logoBoxSix .logoOverlay {
		display: none;
	}
}

.mainContainer .logosWrap .logoBoxSeven {
	background: url(../assets/logos/joules.gif) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .logosWrap .logoBoxSeven .logoOverlay {
	background-color: rgba(39, 165, 136, 0.98);
}

.mainContainer .logosWrap .logoBoxEight {
	background: url(../assets/logos/ricky.svg) no-repeat;
	background-size: cover;
	background-position: center;
	background-position-y: -50px;
}

@media (min-width: 768px) {
	.mainContainer .logosWrap .logoBoxEight {
		background-position-y: 0px;
	}
}

.mainContainer .logosWrap .logoBoxEight .logoOverlay {
	background-color: rgba(186, 36, 29, 0.97);
}

@media (min-width: 768px) {
	.mainContainer .logosWrap .logoBoxEight .logoOverlay {
		top: 350px;
	}
}

@media (min-width: 1440px) {
	.mainContainer .logosWrap .logoBoxEight .logoOverlay {
		top: 0px;
	}
}

@media (min-width: 768px) {
	.mainContainer .logosWrap .logoBoxEight .logoHeader {
		top: 70px;
	}
}

@media (min-width: 1440px) {
	.mainContainer .logosWrap .logoBoxEight .logoHeader {
		top: 230px;
	}
}

.mainContainer .logosWrap .logoBoxNine {
	background: url(../assets/logos/nannys.svg) no-repeat;
	background-size: cover;
	background-position: center;
	background-position-y: -50px;
}

.mainContainer .logosWrap .logoBoxNine .logoOverlay {
	background-color: rgba(163, 81, 55, 0.97);
}

.mainContainer .logosWrap .logoBoxTen {
	background: url(../assets/logos/saAir.svg) no-repeat;
	background-size: cover;
	background-position: center;
	background-position-y: -50px;
}

.mainContainer .logosWrap .logoBoxTen .logoOverlay {
	background-color: rgba(42, 53, 136, 0.97);
}

.mainContainer .logosWrap .logoBoxEleven {
	background: url(../assets/logos/adAwaits.svg) no-repeat;
	background-size: cover;
	background-position: center;
	background-position-y: -50px;
}

.mainContainer .logosWrap .logoBoxEleven .logoOverlay {
	background-color: rgba(41, 25, 97, 0.97);
}

.mainContainer .logosWrap .logoBoxTwelve {
	background: url(../assets/logos/lacquerPress.svg) no-repeat;
	background-size: cover;
	background-position: center;
	background-position-y: -50px;
}

.mainContainer .logosWrap .logoBoxTwelve .logoOverlay {
	background-color: rgba(0, 0, 0, 0.9);
}

.mainContainer .logosWrap .logoBoxThirteen {
	background: url(../assets/logos/dropcap.svg) no-repeat;
	background-size: cover;
	background-position: center;
	background-position-y: -50px;
}

.mainContainer .logosWrap .logoBoxThirteen .logoOverlay {
	background-color: rgba(28, 168, 155, 0.97);
}

.mainContainer .logosWrap .logoBoxFourteen {
	background: url(../assets/logos/david.svg) no-repeat;
	background-size: cover;
	background-position: center;
	background-position-y: -50px;
}

.mainContainer .logosWrap .logoBoxFourteen .logoOverlay {
	background-color: rgba(87, 191, 144, 0.97);
}

.mainContainer .logosWrap .logoBoxFifteen {
	background: url(../assets/logos/starbucks.svg) no-repeat;
	background-size: cover;
	background-position: center;
	background-position-y: -50px;
}

.mainContainer .logosWrap .logoBoxFifteen .logoOverlay {
	background-color: rgba(0, 92, 59, 0.97);
}

.mainContainer .logosWrap .logoBoxSixteen {
	background: url(../assets/logos/masterCuisine.svg) no-repeat;
	background-size: cover;
	background-position: center;
	background-position-y: -50px;
}

.mainContainer .logosWrap .logoBoxSixteen .logoOverlay {
	background-color: rgba(118, 0, 3, 0.97);
}

.mainContainer .logosWrap .logoBoxSeventeen {
	background: url(../assets/logos/calafant.svg) no-repeat;
	background-size: cover;
	background-position: center;
	background-position-y: -50px;
}

.mainContainer .logosWrap .logoBoxSeventeen .logoOverlay {
	background-color: rgba(237, 117, 33, 0.95);
}

.mainContainer .logosWrap .logoBoxEighteen {
	background: url(../assets/logos/kenneth.svg) no-repeat;
	background-size: cover;
	background-position: center;
	background-position-y: -50px;
}

.mainContainer .logosWrap .logoBoxEighteen .logoOverlay {
	background-color: rgba(46, 39, 176, 0.95);
}

.mainContainer .logosWrap .lastLogo {
	margin-bottom: 150px;
}

.mainContainer .musicCont .musicWrap {
	justify-content: flex-start;
}

.mainContainer .musicCont .musicWrap .logoBox {
	background: url(../assets/music/intoTheStorm.jpg) no-repeat;
	background-size: cover;
	background-position: center;
}

@media (min-width: 768px) {
	.mainContainer .musicCont .musicWrap .logoBox {
		height: 500px;
		margin: 0 0 10px .5%;
	}
}

@media (min-width: 1440px) {
	.mainContainer .musicCont .musicWrap .logoBox {
		height: 540px;
		margin: 0 0 13px .5%;
	}
}

.mainContainer .musicCont .musicWrap .logoBox audio {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: 100;
	transition: .4s;
}

.mainContainer .musicCont .musicWrap .logoBox audio:hover {
	opacity: 1;
	transition: .4s;
}

.mainContainer .musicCont .musicWrap .logoBoxTwo {
	background: url(../assets/music/MaidenVoyage.jpg) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .musicCont .musicWrap .logoBoxThree {
	background: url(../assets/music/LullabyWaltz.jpg) no-repeat;
	background-size: cover;
	background-position: center;
}
