$mainFont: niveau-grotesk, sans-serif;
$textColor: #17405B;


/* 4. Logos Page ================================= */

.mainContainer {

	.logosWrap {
		position: relative;
		margin: 20px auto 0px;
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;

		@media( min-width: 768px ) {
			width: 98.75%;
		}

		@media( min-width: 1440px ) {
			width: 99.25%;
		}

		.logoBox {
			background: url(../assets/logos/sunport.gif) no-repeat;
			background-size: cover;
			background-position: center;
			position: relative;
			margin: 150px auto 7px;
			width: 96%;
			height: 350px;
			transition: .3s;
			cursor: pointer;

			@media( min-width: 768px ) {
				width: 49%;
				height: 350px;
			}

			@media( min-width: 1440px ) {
				width: 32.5%;
				margin: 150px auto 7px;
				height: 510px;

				&:hover {
					.logoOverlay {
						opacity: 1;
						transition: .3s;
					}
				}
			}	

			.logoOverlay {
				position: absolute;
			    top: 350px;
			    left: 0;
			    padding: 27px 20px;
			    width: 100%;	
			    height: 150px;
			    font-family: niveau-grotesk, sans-serif;
			    font-size: 18px;
			    font-weight: 300;
			    line-height: 24px;
			    text-align: center;
			    background: rgba(255, 231, 55, 0.95);
			    color: white;
			    display: flex;
			    flex-direction: row;
			    justify-content: center;
			    align-items: flex-end;
			    transition: .3s;

				@media( min-width: 1440px ) {
					align-items: center;
					opacity: 0;
				    top: 0;
				    height: 100%;
				    width: 100%;
				    padding: 0px 50px;
				    font-size: 30px;
				    line-height: 43px;
				}
			}

			.overlayStatic {
				top: 280px;

				@media( min-width: 1440px ) {
					top: 0px;
				}
			}

			.logoHeader {
		        position: relative;
			    margin: 350px auto 0px;
			    width: 100%;
			    height: auto;
			    font-family: niveau-grotesk, sans-serif;
			    font-size: 36px;
			    line-height: 52px;
			    padding: 16px 0px;
			    font-weight: 400;
			    text-align: center;
			    background: none;
			    text-decoration: none;
			    color: white;
			    z-index: 10;

			    @media( min-width: 768px ) {
					// margin: 470px auto 0px;
				}

				@media( min-width: 1440px ) {
					font-size: 42px;
					margin: 510px auto 0px;
					color: black;
				}
			}

			.headerStatic {
				margin: 280px auto 0px;

				@media( min-width: 1440px ) {
					top: 230px;
				}
			}
		}

		.logoBoxZero_1 {
			background: url(../assets/logos/touchsource.gif) no-repeat;
			background-size: cover;
			background-position: center;
			margin: 0 auto 7px;

			.logoOverlay {
				color: white;
				background: rgba(7, 159, 196, .97);
			}

			.logoHeader {
				color: white;
				@media( min-width: 1440px) {
					color: black;
				}
			}

			@media( min-width: 1440px) {
				margin: 0 auto 7px;
			}
		}

		.logoBoxZero_2 {
			background: url(../assets/logos/fishLoop_Square.gif) no-repeat;
			background-size: cover;
			background-position: center;
			margin: 150px auto 7px;

			.logoOverlay {
				color: white;
				background: rgba(21, 1, 52, .95);
			}

			.logoHeader {
				color: white;
				@media( min-width: 1440px) {
					color: black;
				}
			}

			@media( min-width: 768px) {
				margin: 0px auto 7px;
			}
		}

		.logoBoxOne {

			.logoOverlay {
				color: black;
			}

			.logoHeader {
				color: black;
			}

			@media( min-width: 1440px) {
				margin: 0 auto 7px;
			}
		}

		.logoBoxTwo {
			background: url(../assets/logos/moh.gif) no-repeat;
			background-size: cover;
			background-position: center;

			// @media( min-width: 768px ) {
			// 	margin: 0 auto 7px;
			// }

			.logoOverlay {
				background-color: rgba(21, 128, 132, .95);
			}	
		}

		.logoBoxThree {
			background: url(../assets/logos/urban.gif) no-repeat;
			background-size: cover;
			background-position: center;

			.logoOverlay {
				background-color: rgba(64, 11, 117, .91);
			}

			// @media( min-width: 1440px ) {
			// 	margin: 0 auto 7px;
			// }
		}

		.logoBoxFour {
			background: url(../assets/logos/jds.gif) no-repeat;
			background-size: cover;
			background-position: center;

			.logoOverlay {
				background-color: rgba(255, 255, 255, .95);
				color: black;
			}

			.logoHeader {
				color: black;
			}
		}

		.logoBoxFive {
			background: url(../assets/logos/asianGames.gif) no-repeat;
			background-size: cover;
			background-position: center;

			.logoOverlay {
				background-color: rgba(117, 19, 50, .95);
			}
		}

		.logoBoxSix {
			background: white;
			background-size: cover;
			background-position: center;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}

			.logoOverlay {
				background-color: rgba(232, 61, 85, .97);

				@media( min-width: 1440px ) {
					display: none;
				}
			}
		}

		.logoBoxSeven {
			background: url(../assets/logos/joules.gif) no-repeat;
			background-size: cover;
			background-position: center;

			.logoOverlay {
				background-color: rgba(39, 165, 136, .98);
			}
		}

		.logoBoxEight {
			background: url(../assets/logos/ricky.svg) no-repeat;
			background-size: cover;
			background-position: center;
			background-position-y: -50px;

			@media( min-width: 768px ) {
				background-position-y: 0px;
			}

			.logoOverlay {
				background-color: rgba(186, 36, 29, .97);

				@media( min-width: 768px ) {
					top: 350px;
				}

				@media( min-width: 1440px ) {
					top: 0px;
				}
			}

			.logoHeader {
				@media( min-width: 768px ) {
					top: 70px;
				}

				@media( min-width: 1440px ) {
					top: 230px;
				}
			}
		}

		.logoBoxNine {
			background: url(../assets/logos/nannys.svg) no-repeat;
			background-size: cover;
			background-position: center;
			background-position-y: -50px;

			.logoOverlay {
				background-color: rgba(163, 81, 55, .97);
			}
		}

		.logoBoxTen {
			background: url(../assets/logos/saAir.svg) no-repeat;
			background-size: cover;
			background-position: center;
			background-position-y: -50px;

			.logoOverlay {
				background-color: rgba(42, 53, 136, .97);
			}
		}

		.logoBoxEleven {
			background: url(../assets/logos/adAwaits.svg) no-repeat;
			background-size: cover;
			background-position: center;
			background-position-y: -50px;

			.logoOverlay {
				background-color: rgba(41, 25, 97, .97);
			}
		}

		.logoBoxTwelve {
			background: url(../assets/logos/lacquerPress.svg) no-repeat;
			background-size: cover;
			background-position: center;
			background-position-y: -50px;

			.logoOverlay {
				background-color: rgba(0, 0, 0, .9);
			}
		}

		.logoBoxThirteen {
			background: url(../assets/logos/dropcap.svg) no-repeat;
			background-size: cover;
			background-position: center;
			background-position-y: -50px;

			.logoOverlay {
				background-color: rgba(28, 168, 155, .97);
			}
		}

		.logoBoxFourteen {
			background: url(../assets/logos/david.svg) no-repeat;
			background-size: cover;
			background-position: center;
			background-position-y: -50px;

			.logoOverlay {
				background-color: rgba(87, 191, 144, .97);
			}
		}

		.logoBoxFifteen {
			background: url(../assets/logos/starbucks.svg) no-repeat;
			background-size: cover;
			background-position: center;
			background-position-y: -50px;

			.logoOverlay {
				background-color: rgba(0, 92, 59, .97);
			}
		}

		.logoBoxSixteen {
			background: url(../assets/logos/masterCuisine.svg) no-repeat;
			background-size: cover;
			background-position: center;
			background-position-y: -50px;

			.logoOverlay {
				background-color: rgba(118, 0, 3, .97);
			}
		}


		.logoBoxSeventeen {
			background: url(../assets/logos/calafant.svg) no-repeat;
			background-size: cover;
			background-position: center;
			background-position-y: -50px;

			.logoOverlay {
				background-color: rgba(237, 117, 33, .95);
			}
		}

		.logoBoxEighteen {
			background: url(../assets/logos/kenneth.svg) no-repeat;
			background-size: cover;
			background-position: center;
			background-position-y: -50px;

			.logoOverlay {
				background-color: rgba(46, 39, 176, .95);
			}
		}

		.lastLogo {
			margin-bottom: 150px;
		}
	}



	.musicCont {
		.musicWrap {
			justify-content: flex-start;

			.logoBox {
				background: url(../assets/music/intoTheStorm.jpg) no-repeat;
				background-size: cover;
				background-position: center;
				

				@media( min-width: 768px ) {
					height: 500px;
					margin: 0 0 10px .5%;
				}

				@media( min-width: 1440px ) {
					height: 540px;
					margin: 0 0 13px .5%;
				}

				audio {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					opacity: 0;
					z-index: 100;
					transition: .4s;

					&:hover {
						opacity: 1;
						transition: .4s;
					}
				}
			}

			.logoBoxTwo {
				background: url(../assets/music/MaidenVoyage.jpg) no-repeat;
				background-size: cover;
				background-position: center;
			}

			.logoBoxThree {
				background: url(../assets/music/LullabyWaltz.jpg) no-repeat;
				background-size: cover;
				background-position: center;
				// background: black;
			}
		}
	}
}


















